/* ModalStyles.css */

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  /* オーバーレイの背景色を不透明に設定 */
  z-index: 1000;
  /* 他の要素より前面に表示 */
}

.ReactModal__Content {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  /* モーダルの背景を白に設定 */
  padding: 20px;
  border-radius: 4px;
  outline: none;
  z-index: 1001;
  /* オーバーレイより前面に表示 */
}