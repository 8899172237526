@media screen and (min-width: 1025px) {

  .body-1 {
    height: 100% !important;

  }
  .ResetFormTop {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    /* フォームを中央に配置 */
    margin-top: 100px;
    width: 500px !important;
    box-sizing: border-box;
    /* パディングを含めたボックスサイズを計算 */
    transform: scale(1.0)
  }

  .p-text {
    text-align: center;
    margin-top: -80px;
    margin-bottom: 5%;
    font-size: 1.4em;
    color: #333;
  }

  .reset-submit button {
    width: 100%;
    margin-bottom: 5%;
    padding: 12px;
    background-color: #6366f1;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .reset-submit button:hover {
    background-color: #4f46e5;
  }

  h2,
  p,
  label {
    color: #333;
  }

  .error-message,
  .success-message {
    margin-bottom: 10%;
    /* ボタンとの間に15pxのスペースを追加 */
  }
}

/* ===== iPad Mini (768px ~ 768px) ===== */
@media screen and (min-width: 768px) and (max-width: 768px) {

  /* ======== パスワード再設定ページ　======== */
  .ResetFormTop {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    /* フォームを中央に配置 */
    margin-top: 100px;
    width: 500px !important;
    box-sizing: border-box;
    /* パディングを含めたボックスサイズを計算 */
    transform: scale(1.0)
  }

  .body-1 {
    height: 100%;

  }

  h2,
  label {
    color: #333;
    text-align: center;
  }



  .reset-submit button {
    width: 100%;
    margin-bottom: 5%;
    padding: 12px;
    background-color: #6366f1;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .reset-submit button:hover {
    background-color: #4f46e5;
  }

  .error-message,
  .success-message {
    margin-bottom: 10%;
    /* ボタンとの間に15pxのスペースを追加 */
  }

}

/* ===== iPad Air (820px ~ 820px) ===== */
@media screen and (min-width: 820px) and (max-width: 1180px) {

  /* ======== パスワード再設定ページ　======== */
  .ResetFormTop {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    /* フォームを中央に配置 */
    margin-top: 100px;
    width: 500px !important;
    box-sizing: border-box;
    /* パディングを含めたボックスサイズを計算 */
    transform: scale(1.0)
  }

  h2,
  label {
    color: #333;
  }

  .p-text5 {
    font-size: 1.0em;
    text-align: left;
    margin-bottom: 5%;
    color: #333;

  }

  .reset-submit button {
    width: 100%;
    margin-bottom: 5%;
    padding: 12px;
    background-color: #6366f1;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .reset-submit button:hover {
    background-color: #4f46e5;
  }

  .error-message,
  .success-message {
    margin-bottom: 10%;
    /* ボタンとの間に15pxのスペースを追加 */
  }

}

/* ===== Surface Duo (540px ~ 540px) ===== */
@media screen and (min-width: 540px) and (max-width: 540px) {

  /* ======== パスワード再設定ページ　======== */
  .ResetFormTop {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    /* フォームを中央に配置 */
    margin-top: 100px;
    width: 500px !important;
    box-sizing: border-box;
    /* パディングを含めたボックスサイズを計算 */
    transform: scale(1.0)
  }

  h2,
  label {
    color: #333;
  }

  .p-text5 {
    font-size: 1.0em;
    text-align: left;
    margin-bottom: 5%;
    color: #333;

  }

  .reset-submit button {
    width: 100%;
    margin-bottom: 5%;
    padding: 12px;
    background-color: #6366f1;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .reset-submit button:hover {
    background-color: #4f46e5;
  }

  .error-message,
  .success-message {
    margin-bottom: 10%;
    /* ボタンとの間に15pxのスペースを追加 */
  }


}