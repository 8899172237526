@media screen and (min-width: 768px) and (max-width: 820px) {

  table {
    display: none;
  }

  html,
  body {
    overflow-x: hidden;
    width: 90vh;
  }

  .colorLabel {
    color: red;
  }

  /* PC版の吹き出しをスマホで非表示にする */
  .dashboard-container {
    height: 135vh;
  }

  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 90%;
    height: 60%;
    margin-left: 90%;
    margin-top: -200px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    width: 200%;
    cursor: pointer;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 20px;
    margin-top: 5px;
    width: 200%;
  }

  li {
    width: 300%;
  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 1.1em;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -250px;
    margin-left: 200px;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -27px;
    padding: 10px;
    background-color: #def0f7;
    width: 700px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 70%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 40px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }

}

@media screen and (min-width: 1024px) and (max-width: 1024px) {

  table {
    display: none;
  }

  /* PC版の吹き出しをスマホで非表示にする */
  .dashboard-container {
    height: 135vh;
    margin-left: 10%;
  }

  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 100%;
    height: 60%;
    margin-left: 100%;
    margin-top: -200px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    width: 200%;
    cursor: pointer;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 20px;
    margin-top: 5px;
    width: 200%;
  }

  li {
    width: 300%;
  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 1.1em;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -250px;
    margin-left: 70%;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -27px;
    padding: 10px;
    background-color: #def0f7;
    width: 700px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 70%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 40px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }

}

@media screen and (min-width: 912px) and (max-width: 912px) {

  /* PC版の吹き出しをスマホで非表示にする */
  .dashboard-container {
    height: 135vh;
    margin-left: 10%;
  }

  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 100%;
    height: 60%;
    margin-left: 100%;
    margin-top: -200px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    width: 200%;
    cursor: pointer;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 20px;
    margin-top: 5px;
    width: 200%;
  }

  li {
    width: 300%;
  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 1.1em;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -250px;
    margin-left: 70%;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -27px;
    padding: 10px;
    background-color: #def0f7;
    width: 700px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 70%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 40px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }

}

@media screen and (min-width: 540px) and (max-width: 540px) {

  /* PC版の吹き出しをスマホで非表示にする */
  .dashboard-container {
    height: 180vh;
    margin-left: 0%;
  }

  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 70%;
    height: 60%;
    margin-left: 60%;
    margin-top: -200px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    width: 140%;
    cursor: pointer;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
  }

  .accordion-content {
    width: 55%;
    padding: 10px;
    margin-left: -20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 20px;
    margin-top: 5px;
    width: 250%;
  }

  li {
    width: 300%;
  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 1.1em;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -200px;
    margin-left: 20%;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -27px;
    padding: 10px;
    background-color: #def0f7;
    width: 500px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 70%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 40px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }

}