/* ====== PCスタイル ====== */
@media screen and (min-width: 1025px) {
  .p-text3 {
    text-align: center;
    margin-left: -2% !important;
    margin-top: -50px !important;
    margin-bottom: 20px;
    font-size: 1.2em;
  }

  .login-link-postion {
    margin-top: 0px;
  }
  /* パスワードをお忘れの方 */
  .login-link:link {
    color: #717171;
    /* クリック前の色 */
  }

  .login-link:visited {
    color: #717171;
    /* クリック後も同じ色 */
  }

  .login-link:hover {
    color: #0056b3;
    /* マウスが乗ったときの色（任意） */
  }

  .login-link:active {
    color: #004085;
    /* クリック中の色（任意） */
  }

  a {
    text-decoration: none;
  }

}

/* ====== iPhone SE ====== */
@media screen and (min-width: 375px) and (max-width: 430px){
  .p-text3 {
    text-align: center;
    margin-left: 0px !important;
    margin-top: -50px !important;
    margin-bottom: 40px;
    font-size: 22px !important;
    color: #333;
  }

  /* パスワードをお忘れの方 */
  .login-link:link {
    color: #717171;
    /* クリック前の色 */
  }

  .login-link:visited {
    color: #717171;
    /* クリック後も同じ色 */
  }

  .login-link:hover {
    color: #0056b3;
    /* マウスが乗ったときの色（任意） */
  }

  .login-link:active {
    color: #004085;
    /* クリック中の色（任意） */
  }

  a {
    text-decoration: none;
  }

  .login-link-postion {
    margin-top: 20px;
  }

  .RegisterFormTop {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    /* フォームを中央に配置 */
    margin-top: 0px;
    width: 90%;
    /* フォームの幅を画面幅の90%に設定 */
    box-sizing: border-box;
    /* パディングを含めたボックスサイズを計算 */
  }
}

/* ====== Samsung Galaxy S8+  ====== */
@media screen and (min-width: 360px) {
  .p-text3 {
    text-align: center;
    margin-left: -10%;
    margin-top: -80px;
    font-size: 1.2em;
    color: #333;
  }

  /* パスワードをお忘れの方 */
  .login-link-postion {
    margin-top: 20px;
  }
  .login-link:link {
    color: #717171;
    /* クリック前の色 */
  }

  .login-link:visited {
    color: #717171;
    /* クリック後も同じ色 */
  }

  .login-link:hover {
    color: #0056b3;
    /* マウスが乗ったときの色（任意） */
  }

  .login-link:active {
    color: #004085;
    /* クリック中の色（任意） */
  }

  a {
    text-decoration: none;
  }

  .RegisterFormTop {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    /* フォームを中央に配置 */
    margin-top: 0px;
    width: 90%;
    /* フォームの幅を画面幅の90%に設定 */
    box-sizing: border-box;
    /* パディングを含めたボックスサイズを計算 */
  }
}