/* src/App.css または他のCSSファイル */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

/* PC向けのスタイル */
@media screen and (min-width: 1025px) {
  body {
    font-family: 'Noto Sans JP', sans-serif;
  }

  /* ヘッダーメニュー下のサブメニュー的なもの */
  .page-line-text {
    color: #333;
    font-family: 'Noto Sans JP', sans-serif;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* 垂直方向に中央揃え */
    margin-top: 15px;
    margin-right: auto;
    margin-left: 250px;
    max-width: 1200px;
    /* 最大幅を設定 */
    font-size: larger;
  }

  .image-container {
    display: block;
    margin-top: 140px;
  }
  .text-content {
    text-align: left;
  }

  /* キャラクター画像設定 */
  .mascot-image {
    position: fixed;
    max-width: 60%;
    height: auto;
    margin-top: 50px;
    margin-left: 1000px;
    width: 25%;
  }

  .mobile-accordion {
    display: block;
    margin-top: -150px;
  }

  .speech-bubble {
    position: fixed;
    font-size: 15px;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .bubble-postion {
    position: fixed;
    margin-left: 870px;
    margin-top: 190px !important;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    display: block;
  }

  /* PC表示時のヘルプページにある吹き出しレイアウト */
  .speech-bubble-pc {
    position: relative;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    margin: 0 90px;
    height: 200px;
  }

  .speech-bubble-pc::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #f0f0f0 transparent;
    display: block;
    width: 0;
  }

  .menu-content.active {
    display: block;
    animation: fadeIn 0.5s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .mobile-help-text3 {
    display: none;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    padding: 20px;
    margin-left: -2%;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 70%;
  }
  .support-table {
    margin-top: 10px;
    margin-left: -27px;
    padding: 10px;
    background-color: #def0f7;
    width: 50vw;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .label-text {
    display: flex;
    margin-bottom: 30px;
    font-size: 18px;
    color: #333;
  }

  .labelText1 {
    display: flex;
    color: #333;
    font-size: 18px;
  }

  .labelText3 {
    display: flex;
    margin-top: 5px !important;
    margin-bottom: -10px;
    color: #333;
  }

  .labelText2 {
    display: flex;
    margin-top: 5px !important;
    margin-bottom: -10px;
    color: #333;
  }


  .radio-option1 {
    display: inline-flex;
    gap: 2px;
    margin-bottom: 20px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }


  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 850px;
    height: 300px;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 69%;
    margin-top: 0;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }
  .colorLabel {
    color: red;
  }

  .accordion-content-open {
    margin-left: -30px;
    line-height: 35px
  }

  .accordion-content-open1 {
    margin-left: -20px;
    width: 71%;
    line-height: 35px
  }

}

/* スマホ向けのスタイル */
@media screen and (min-width: 430px) and (max-width: 430px) {
  table {
    display: none;
  }

  .mobile-help-text3 {
    position: fixed;
    font-size: 20px;
    font-family: "sans-serif";
    color: #646c79;
    font-weight: bold;
    text-align: center;
    margin-top: -165px !important;
    margin-left: 70px;
    width: 300px;
    z-index: 4000;
  }

  html,
  body {
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
  }

  .colorLabel {
    color: red;
  }

  .dashboard-container {
    margin-left: 20px;
    width: 100vw;
  }

  /* PC版の吹き出しをスマホで非表示にする */
  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 60%;
    height: auto;
    margin-left: 110px;
    margin-top: -150px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    cursor: pointer;
    padding: 15px;
    margin-left: -2%;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 42vh;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 40px;
    margin-top: 5px;

  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 15px;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -150px;
    margin-left: -30px;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -27px;
    padding: 10px;
    background-color: #def0f7;
    width: 90vw;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .labelText3 {
    display: flex;
    margin-top: 5px !important;
    margin-bottom: -10px;
    color: #333;
  }

  .labelText2 {
    display: flex;
    margin-top: 5px !important;
    margin-bottom: -10px;
    color: #333;
  }


  .radio-option1 {
    display: inline-flex;
    gap: 2px;
    margin-bottom: 20px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 69%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }
}

@media screen and (min-width: 414px) and (max-width: 414px) {
  .mobile-help-text3 {
    position: fixed;
    font-size: 20px;
    font-family: "sans-serif";
    color: #646c79;
    font-weight: bold;
    text-align: center;
    margin-top: -165px !important;
    margin-left: 70px;
    width: 300px;
    z-index: 4000;
  }

  .dashboard-container {
    margin-left: 10px;

  }

  .support-table {
    margin-top: 10px;
    margin-left: -4%;
    padding: 10px;
    background-color: #def0f7;
    width: 360px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
    margin-left: -2%;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: 0px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    cursor: pointer;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 100vh;
    margin-left: -1% !important;
  }


}

@media screen and (min-width: 360px) and (max-width: 360px) {

  /* PC版の吹き出しをスマホで非表示にする */
  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 60%;
    height: auto;
    margin-left: 110px;
    margin-top: -150px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    cursor: pointer;
    padding: 15px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 43vh;
    margin-left: -2% !important;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -30px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 40px;
    margin-top: 5px;

  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 15px;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -150px;
    margin-left: -30px;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -9%;
    padding: 10px;
    background-color: #def0f7;
    width: 330px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 69%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }


}

@media screen and (min-width: 375px) and (max-width: 375px) {

  table {
    display: none;
  }

  .mobile-help-text3 {
    position: fixed;
    font-size: 16px;
    font-family: "sans-serif";
    color: #646c79;
    font-weight: bold;
    text-align: center;
    margin-top: -165px !important;
    margin-left: 70px;
    width: 300px;
    z-index: 4000;
  }

  html,
  body {
    overflow-x: hidden;
    width: 100vw;
  }

  .colorLabel {
    color: red;
  }

  /* PC版の吹き出しをスマホで非表示にする */
  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 60%;
    height: auto;
    margin-left: 110px;
    margin-top: -150px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    cursor: pointer;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 50vh;
    margin-left: -2%;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -30px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 40px;
    margin-top: 5px;

  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 15px;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -150px;
    margin-left: -30px;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -8%;
    padding: 10px;
    background-color: #def0f7;
    width: 330px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
  }

  .label-text2 {
    display: flex;
    margin-bottom: 0px !important;
    font-size: 1.2em;
    color: #333;
  }


  .radio-option1 {
    display: inline-flex;
    gap: 2px;
    margin-bottom: 15px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 69%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 5px;
    /* 各要素の間に十分なスペースを設けます */
  }


}

@media screen and (min-width: 412px) and (max-width: 414px) {

  table {
    display: none;
  }

  html,
  body {
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
  }

  .colorLabel {
    color: red;
  }

  /* PC版の吹き出しをスマホで非表示にする */
  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 60%;
    height: auto;
    margin-left: 110px;
    margin-top: -150px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    cursor: pointer;
    padding: 15px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 40vh;
    margin-left: 0% !important;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -30px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 40px;
    margin-top: 5px;

  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 15px;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -150px;
    margin-left: -30px;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -9%;
    padding: 10px;
    background-color: #def0f7;
    width: 380px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 69%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }


}

@media screen and (min-width: 390px) and (max-width: 390px) {
  table {
    display: none;
  }
  .mobile-help-text3 {
    position: fixed;
    font-size: 20px;
    font-family: "sans-serif";
    color: #646c79;
    font-weight: bold;
    text-align: center;
    margin-top: -165px !important;
    margin-left: 70px;
    width: 300px;
    z-index: 4000;
  }

  html,
  body {
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
  }

  .colorLabel {
    color: red;
  }


  /* PC版の吹き出しをスマホで非表示にする */
  .speech-bubble-pc {
    display: none;
  }

  .form-input-context {
    width: 300px;
    height: 200px;
  }

  .help-page-container {
    line-height: 1.6;
    padding: 20px;
    border-radius: 8px;
    width: 390px;
  }

  .image-container {
    display: block;
    align-items: center;
  }

  .mascot-image {
    max-width: 60%;
    height: auto;
    margin-left: 110px;
    margin-top: -150px;
  }

  .accordion {
    margin-left: -30px;
    width: 350px;
  }

  .accordion h4 {
    margin-left: 30px;
    margin-top: -30px;
    width: 350px;
  }

  .mobile-accordion {
    display: block;
    margin-top: 90px;
  }

  .accordion-title {
    background: linear-gradient(135deg, #fefaff, #d3f7ff);
    font-size: 15px;
    cursor: pointer;
    padding: 15px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    width: 42vh;
    margin-left: -2% !important;
  }

  .accordion-content {
    display: none;
    padding: 10px;
    margin-left: -30px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    line-height: 1.8;
    color: #333;
  }

  p {
    margin-left: 40px;
    margin-top: 5px;

  }

  .accordion-content.open {
    display: block;
  }

  .text-content {
    display: none;
  }

  .speech-bubble {
    font-size: 15px;
    background-color: #ffffe2;
    font-family: 'ヒラギノ角ゴシック', 'Arial', sans-serif;
    border: 2px solid #efe78b;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    margin-top: -150px;
    margin-left: -30px;
    display: block;
    width: 150px;
    border-radius: 10px;
    position: relative;
    padding: 10px;
  }

  .speech-bubble::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #efe89a;
    transform: translateY(-50%);
  }

  .support-table {
    margin-top: 10px;
    margin-left: -8%;
    padding: 10px;
    background-color: #def0f7;
    width: 350px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
  }

  .menu-content {
    display: none;
  }

  .label-text {
    display: flex;
    margin-bottom: 5%;
    font-size: 1.2em;
    color: #333;
  }

  .radio-option1 {
    display: inline-flex;
    gap: 2px;
  }

  .radio-option2 {
    display: inline;
  }

  input[type="text"],
  input[type="email"],
  textarea[id="content"] {
    border: 1.5px solid #d1d5db;
    border-radius: 4px;
    background-color: #f9fafb;
    font-size: 0.9rem;
    color: #333;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
  }

  /* 隠すためのスタイル */
  .form-input-file {
    display: none;
    /* ファイル入力要素を隠す */
  }

  /* カスタムのファイル選択ボタン */
  .custom-file-upload {
    display: block;
    padding: 12px 0px;
    background-color: #2c65ba;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 1.5
  }

  .submit-hbutton {
    width: 30%;
    margin-left: 69%;
    margin-top: -5%;
    padding: 12px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  /* コンテナを調整して一貫した配置に */
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /* ファイルアップロードセクションの調整 */
  .file-upload {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .file-upload.visible {
    height: auto;
    margin-bottom: 10px;
  }

  .button-container {
    display: flex;
    margin-top: 20px;
  }

  /* フォーム要素の調整 */
  .form-group {
    margin-bottom: 10px;
    /* 各要素の間に十分なスペースを設けます */
  }


}