/* PC表示用 */
@media (min-width: 1025px) {

.scheduleContainer {
  width: 800px;
  height: 450px;
  max-width: 100vw !important;
  margin-top: -960px;
  margin-left: 980px;
  text-align: center;
  background: linear-gradient(90deg, #f5d5d5, #f3c6f1);
}

.prepareImage{
  width: 90%;
  height: 90%;
  padding-top: 20px;
}

.scheduleTitle {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  color: #FFF;
  text-shadow: 0 1px 2px rgba(230, 140, 185, 0.945);
}

.scheduleTable {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scheduleRow {
  display: flex;
  background-color: rgba(255, 255, 255, 0.788);
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 10px 15px;
  width: 70%;
  box-sizing: border-box;
}

.day, .time, .activity {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  min-width: 0; /* Flexboxの溢れ防止 */
}

.day {
  font-size: 15px;
  font-family: "Kaisei Tokumin";
  font-weight: bold;
  color: #ffa1b6;
  text-align: left;
}

.time {
  font-size: 15px;
  font-family: "Kaisei Tokumin";
  font-weight: bold;
  color: #ffa1b6;
  text-align: left;
  margin-left: 10px;
}

.activity {
  color: #ffa1b6;
  font-family: "Mochiy Pop One", sans-serif;
  font-weight: 400;
  text-align: left; /* テキストを左揃えに */
  margin-left: 20px;
}
}



/* スマホ用のメディアクエリ */
@media screen and (min-width: 375px) and (max-width: 430px) {
  .scheduleContainer {
    width: 100%;
    max-width: 450px; /* 450pxを上限に設定 */
    margin-top: 170px;
    flex-shrink: 0;
    text-align: center;
    background: linear-gradient(90deg, #f5d5d5, #f3c6f1);
  }

  .prepareImage{
    width: 100%;
    height: 100%;
    padding-top: 0px;
  }

  .scheduleTitle {
    font-size: 1.3em; /* タイトルのフォントサイズを少し小さく */
    margin-bottom: 20px !important;
  }

  .scheduleTable {
    align-items: flex-start;  /* 左揃えにする */
  }

  .scheduleRow {
    padding: 10px 10px;  /* パディングを減らしてスペースを確保 */
    margin: 5px 0;
  }

  .day, .time, .activity {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    flex: 1;
    min-width: 0;
    max-width: 100%; /* 要素の幅を親コンテナに収める */
  }
  .scheduleTitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 14px;
    padding-top: 20px;
    padding-bottom: 0px;
    color: #FFF;
    text-shadow: 0 1px 2px rgba(230, 140, 185, 0.945);
  }
  
  .scheduleTable {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .scheduleRow {
    display: flex;
    background-color: rgba(255, 255, 255, 0.788);
    border-radius: 20px;
    padding: 10px 15px;
    margin: 5px;
    margin-bottom: 10px;
    width: 97%;
    box-sizing: border-box;
  }
  
  .day, .time, .activity {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    min-width: 0; /* Flexboxの溢れ防止 */
  }
  
  .day {
    font-size: 13px;
    font-family: "Kaisei Tokumin";
    font-weight: bold;
    color: #ffa1b6;
    text-align: left;
  }
  
  .time {
    margin-left: -70px;
    font-size: 13px;
    font-family: "Kaisei Tokumin";
    font-weight: bold;
    color: #ffa1b6;
    text-align: left;
  }
  
  .activity {
    margin-left: -60px;
    font-size: 13px;
    color: #ffa1b6;
    font-family: "Mochiy Pop One", sans-serif;
    font-weight: 400;
    text-align: left; /* テキストを左揃えに */
  }
}
